import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import FormComp from "../components/FormComp"
import ImageBanner from "../components/ImageBanner"
import Layout from "../components/Layout"
import Map from "../components/Map"
import Seo from "../components/Seo"

const ContactUs = ({ data }) => {
  const { strapiContactPage: info } = data

  return (
    <Layout>
      <Seo Sitetitle="Contact Us" />
      <ImageBanner heading={info.banner_heading} img={info.banner_image.url} />
      <Container className="my-5">
        <Row className="justify-content-center align-items-center">
          <Col lg={6}>
            <h2>{info.contact_heading}</h2>
            <p className="my-3 h5">{info.address}</p>
            <p className="my-3">{info.timings}</p>
          </Col>
          <Col lg={6}>
            <FormComp />
          </Col>
        </Row>
      </Container>
      <Map />
    </Layout>
  )
}

export const data = graphql`
  {
    strapiContactPage {
      banner_heading
      banner_image {
        url
      }
      contact_heading
      address
      timings
    }
  }
`

export default ContactUs
