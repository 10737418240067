import React, { useState } from "react"
import { Form, Spinner } from "react-bootstrap"
import emailjs from "emailjs-com"
import { navigate } from "gatsby"

const FormComp = () => {
  const [loading, setLoading] = useState(false)

  const handleEmail = e => {
    e.preventDefault()
    setLoading(true)
    emailjs
      .sendForm(
        "service_cv5fcsk",
        "template_fzhpbiq",
        e.target,
        "user_hvy326ylYlI2y1aItpEbH"
      )
      .then(
        result => {
          console.log(result.text)
          navigate("/thank-you")
          setLoading(false)
        },
        error => {
          console.log(error.text)
          setLoading(false)
        }
      )
  }

  return (
    <Form className="my-form my-3" onSubmit={handleEmail}>
      <Form.Group controlId="exampleForm.ControlInput1">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter your name"
          required
          name="name"
          className="my-2"
        />
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlInput2">
        <Form.Label>Email address</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter your email address"
          required
          name="email"
          className="my-2"
        />
      </Form.Group>
      <Form.Group controlId="exampleForm.ControlTextarea3">
        <Form.Label>Message</Form.Label>
        <Form.Control
          as="textarea"
          rows={7}
          required
          name="message"
          placeholder="message"
          className="my-2"
        />
      </Form.Group>
      {loading ? (
        <div className="d-grid gap-2">
          <button className="btn btn-yellow" disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            loading
            <span className="visually-hidden">Loading...</span>
          </button>
        </div>
      ) : (
        <div className="d-grid gap-2">
          <button className="btn btn-yellow" type="submit">
            submit
          </button>
        </div>
      )}
    </Form>
  )
}

export default FormComp
