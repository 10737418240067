import React from "react"

const Map = () => {
  return (
    <div className="maps">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d405731.60585752764!2d-121.9783255!3d37.395078!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fc9c462e28c19%3A0x9d26dd57ff9bc3da!2s4500%20Great%20America%20Pkwy%2C%20Santa%20Clara%2C%20CA%2095054%2C%20USA!5e0!3m2!1sen!2sin!4v1631260230821!5m2!1sen!2sin"
        height="450"
        title="maps"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
      ></iframe>
    </div>
  )
}

export default Map
